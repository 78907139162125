enum APP_ENV_VARS {
	"REACT_APP_IK_SERVICES_ECOMM_SERVICE" = "REACT_APP_IK_SERVICES_ECOMM_SERVICE",
	"REACT_APP_IK_SERVICES_IFT_SERVICE" = "REACT_APP_IK_SERVICES_IFT_SERVICE",
	"REACT_APP_DATADOG_ENABLED" = "REACT_APP_DATADOG_ENABLED",
	"REACT_APP_STAGE" = "REACT_APP_STAGE",
	"REACT_APP_SERVICE" = "REACT_APP_SERVICE",
	"REACT_APP_MONITORING_ENDPOINT" = "REACT_APP_MONITORING_ENDPOINT",
	"REACT_APP_DATADOG_APP_ID" = "REACT_APP_DATADOG_APP_ID",
	"REACT_APP_DATADOG_CLIENT_TOKEN" = "REACT_APP_DATADOG_CLIENT_TOKEN",
	"REACT_APP_TIMEOUT" = "REACT_APP_TIMEOUT",
	"REACT_APP_GPAY_ENVIRONMENT" = "REACT_APP_GPAY_ENVIRONMENT",
	"REACT_APP_IK_PAYMENT_CNP_SERVICE" = "REACT_APP_IK_PAYMENT_CNP_SERVICE",
	"REACT_APP_CUSTOM_SUB_DOMAIN_NAME" = "REACT_APP_CUSTOM_SUB_DOMAIN_NAME",
	"REACT_APP_DATADOG_RUM_ENABLED" = "REACT_APP_DATADOG_RUM_ENABLED",
	"REACT_APP_DATADOG_RUM_APP_ID" = "REACT_APP_DATADOG_RUM_APP_ID",
	"REACT_APP_DATADOG_RUM_CLIENT_TOKEN" = "REACT_APP_DATADOG_RUM_CLIENT_TOKEN",
}
function getEnvVal(key: APP_ENV_VARS): string {
	const val = process.env[key]
	if (val) {
		return val
	}

	throw new Error(`Environment variable '${key}' is required`)
}

function getEnvValOrNull(key: APP_ENV_VARS): string | null {
	const val = process.env[key]
	if (val) {
		return val
	}

	return null
}

const dataDogEnabled = getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_ENABLED)

let dataDogConfig: { datadogAppId: string; datadogClientToken: string } | undefined

if (dataDogEnabled === "true") {
	dataDogConfig = {
		datadogAppId: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_APP_ID),
		datadogClientToken: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_CLIENT_TOKEN),
	}
}

export const appConfig = {
	ecommServiceUrl: getEnvVal(APP_ENV_VARS.REACT_APP_IK_SERVICES_ECOMM_SERVICE),
	iftServiceUrl: getEnvVal(APP_ENV_VARS.REACT_APP_IK_SERVICES_IFT_SERVICE),
	stage: getEnvVal(APP_ENV_VARS.REACT_APP_STAGE),
	service: getEnvVal(APP_ENV_VARS.REACT_APP_SERVICE),
	monitoringEndpoint: getEnvVal(APP_ENV_VARS.REACT_APP_MONITORING_ENDPOINT),
	enableDatadog: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_ENABLED),
	...dataDogConfig,
	timeout: Number(getEnvVal(APP_ENV_VARS.REACT_APP_TIMEOUT)),
	gpayEnvironment: getEnvValOrNull(APP_ENV_VARS.REACT_APP_GPAY_ENVIRONMENT) || "TEST",
	dataDogConfigRumEnabled: getEnvValOrNull(APP_ENV_VARS.REACT_APP_DATADOG_RUM_ENABLED) || "FALSE",
	dataDogConfigRumAppId: getEnvValOrNull(APP_ENV_VARS.REACT_APP_DATADOG_RUM_APP_ID) || "",
	dataDogConfigRumClientToken: getEnvValOrNull(APP_ENV_VARS.REACT_APP_DATADOG_RUM_CLIENT_TOKEN) || "",
}

export const appConfigCNP = {
	paymentCNPServiceUrl: getEnvVal(APP_ENV_VARS.REACT_APP_IK_PAYMENT_CNP_SERVICE),
	customDomain: getEnvVal(APP_ENV_VARS.REACT_APP_CUSTOM_SUB_DOMAIN_NAME),
	stage: getEnvVal(APP_ENV_VARS.REACT_APP_STAGE),
	service: getEnvVal(APP_ENV_VARS.REACT_APP_SERVICE),
	monitoringEndpoint: getEnvVal(APP_ENV_VARS.REACT_APP_MONITORING_ENDPOINT),
	enableDatadog: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_ENABLED),
	...dataDogConfig,
	timeout: Number(getEnvVal(APP_ENV_VARS.REACT_APP_TIMEOUT)),
}
