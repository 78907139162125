import { datadogRum } from "@datadog/browser-rum"
import { appConfig } from "../utils/config"

const allowedTracingUrls = (url: string): boolean => {
	const allowedEndpoints = ["ikhokha.red", "ikhokha.green", "ikhokha.com", "ikhokha.io"]

	if (url.includes("ecomm-storage-bucket")) return false

	return allowedEndpoints.some((endPoint) => {
		return url.includes(endPoint)
	})
}

/**
 * Init Datadog Realtime User Monitoring
 */
const initDataDogRum = () => {
	if (appConfig.dataDogConfigRumEnabled === "TRUE") {
		datadogRum.init({
			applicationId: appConfig.dataDogConfigRumAppId ?? "",
			clientToken: appConfig.dataDogConfigRumClientToken ?? "",
			site: "datadoghq.eu",
			service: "ecomm",
			env: appConfig.stage ?? "",
			version: process.env.npm_package_version,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			useSecureSessionCookie: true,
			defaultPrivacyLevel: "mask-user-input",
			allowedTracingUrls: [
				{
					match: (url: string) => allowedTracingUrls(url),
					propagatorTypes: ["datadog"],
				},
			],
		})
	}
}

export const initDataDog = () => {
	initDataDogRum()
}
