import axios, { AxiosError } from "axios"
import { appConfig } from "./config"
import { CreateTransactionResponse, UserIdentities } from "@ikhokha/commons-ecomm/dist/types/Types"

// TODO: Refactor this. Isolating types during dev
namespace EcommService {
	export type ErrorHeader = {
		errorStatus: boolean
		errorCode: number
		errorMessage: string
	}

	export type CNPPaymentErrorResponse = {
		errorResponse: {
			errorCode: string
			errorMessage: string
			displayedErrorHeader: string
			displayedErrorMessage: string
			displayTryAgain: boolean
		}
	}

	export function isErrorHeader(x: unknown): x is ErrorHeader {
		return (
			!!x &&
			typeof (x as ErrorHeader).errorStatus === "boolean" &&
			(typeof (x as ErrorHeader).errorCode === "number" || typeof (x as ErrorHeader).errorCode === "string") &&
			typeof (x as ErrorHeader).errorMessage === "string"
		)
	}

	type PureThreeDsEnrollResponse = {
		errorStatus: boolean
		errorCode: number
		errorMessage: string
		// FIXME: Confirm we alway get this as types are optional
		redirectToACSForm: string
		status: string
	}

	export type ThreeDsEnrollRequest = {
		paymentLinkToken: string
	}

	export type PerformTransactionRequest = {
		paymentLinkToken: string
		localTransmissionDateTimeInUnixEpocSec: number
	}

	/**
	 * FIXME: Copied these types from backend
	 */
	export type ThreeDsEnrollResponse = ErrorHeader | (PureThreeDsEnrollResponse & ErrorHeader)
	export function isThreeDsEnrollResponse(x: ThreeDsEnrollResponse): x is PureThreeDsEnrollResponse {
		return (x as ThreeDsEnrollResponse).errorStatus === false && (x as PureThreeDsEnrollResponse).status !== undefined
	}

	type PurePerformTransactionResponse = {
		responseStatus: string
	}

	export type PerformTransactionResponse =
		| ErrorHeader
		| PurePerformTransactionResponse
		| (PurePerformTransactionResponse & ErrorHeader)

	export type PerformTransactionErrorResponse = PurePerformTransactionResponse & CNPPaymentErrorResponse

	export function isPerformTransactionResponse(x: PerformTransactionResponse): x is PurePerformTransactionResponse {
		return (
			(x as PurePerformTransactionResponse).responseStatus !== undefined &&
			// FIXME: Fix the typing errors
			//@ts-ignore
			(x as PerformTransactionResponse).errorStatus === undefined
		)
	}

	export type ExternalRefRequest = {
		externalReference: string
	}
	export type ThreeDsPreEnrollRequest = {
		pan: string
		expiryDate: string
		cvv: string
		cardholderName: string
		purchaseAmount: string
		currencyCode: string
		paymentLinkToken: string
	}

	type PurePreEnrollRequest = {
		/**
		 * FIXME: Should add a enum in future
		 */
		status: string
		tdsMethodContent: string
	}

	/**
	 * FIXME: Just making assumptions here
	 */
	export type ThreeDsPreEnrollResponse = ErrorHeader | (PurePreEnrollRequest & ErrorHeader)

	export function isThreePreDsPreEnrollResponse(x: ErrorHeader | PurePreEnrollRequest): x is PurePreEnrollRequest {
		return (x as ThreeDsEnrollResponse).errorStatus === false && (x as PurePreEnrollRequest).status !== undefined
	}
}
export type CNPErrorResponse = {
	errorCode: string
	errorMessage: string
	displayedErrorHeader: string
	displayedErrorMessage: string
	displayTryAgain: boolean
}
/**
 * Limited data from the 'Get Payment Link Details' API
 */
type PayLinkDataRawResponse = {
	id: string
	client: {
		platformName: string
		platformVersion: string
	}
	owner: string
	amount: number
	applicationId: string
	successUrl: string
	failUrl: string
	callbackUrl: string
	status: string
	customerPhone?: string
	customerEmail?: string
	customerName?: string
	/**
	 * This is currently unused and does not need a type
	 */
	transactions: []
	createdAt: string
	modifiedAt: string
	test: boolean
	cancelUrl?: string
	type: string
	tradingName?: string
	customReference?: string
	description?: string
	ecommLimitOverride?: number
	businessLogo?: {
		original: string
		cropped: string
		small: string
		medium: string
		large: string
		thumbnail: string
	}
}

type PayLinkRawData = {
	client: {
		platformName: string
		platformVersion: string
	}
	amount: number
	status: string
	customerName?: string
	successUrl: string
	failUrl: string
	test: boolean
	cancelUrl?: string
	owner: string
	type: string
	tradingName?: string
	customReference?: string
	createdAt?: string
	description?: string
	customerEmail?: string
	ecommLimitOverride?: number
	businessLogo?: {
		original: string
		cropped: string
		small: string
		medium: string
		large: string
		thumbnail: string
	}
}

type HttpErrorResponse = { success: false; statusCode: number; message?: string; cnpErrorDefined?: CNPErrorDefined }
type CNPErrorDefined = { header: string; message: string; tryAgain: boolean }
type HttpSuccessResponse<ResponseData> = { success: true; data: ResponseData }

const generateHttpError = (e: unknown): HttpErrorResponse => {
	// Check for an axios code that isn't our expected success code
	const status = (e as AxiosError).response?.status
	const errorMessage = (e as AxiosError).response?.data?.error

	if (status !== undefined) {
		if (typeof errorMessage === "string") {
			console.log("1. " + errorMessage)
			return {
				success: false,
				statusCode: status,
				message: errorMessage,
			}
		}
		console.log("2. " + errorMessage)
		return {
			success: false,
			statusCode: status,
		}
	} else {
		console.log("3. " + errorMessage)
		return {
			success: false,
			statusCode: 500,
			message: "Please try again or contact support.",
		}
	}
}

type HttpCall<Args, ResponseData> = (args: Args) => Promise<HttpSuccessResponse<ResponseData> | HttpErrorResponse>

export const getPaymentLinkDetails: HttpCall<{ token: string }, PayLinkRawData> = async ({ token }) => {
	try {
		// FIXME: Add run time checking of the returned data
		const response = await axios.get<PayLinkDataRawResponse>(`https://${appConfig.ecommServiceUrl}/${token}/details`, {
			timeout: appConfig.timeout,
		})

		const {
			data: {
				amount,
				client,
				status,
				customerName,
				failUrl,
				successUrl,
				test,
				cancelUrl,
				owner,
				type,
				createdAt,
				tradingName,
				customReference,
				description,
				customerEmail,
				ecommLimitOverride,
				businessLogo,
			},
		} = response

		return {
			success: true,
			data: {
				amount: amount,
				client: client,
				status: status,
				customerName: customerName,
				failUrl: failUrl,
				successUrl: successUrl,
				test: test,
				cancelUrl: cancelUrl,
				owner,
				type,
				tradingName: tradingName,
				customReference: customReference,
				createdAt,
				description,
				customerEmail,
				ecommLimitOverride,
				businessLogo,
			},
		}
	} catch (e) {
		console.log(e)
		return generateHttpError(e)
	}
}

type ThreeDsEnrollResponse = {
	redirectToACSForm: string
	status: string
}

export const threeDsEnroll: HttpCall<{ token: string }, ThreeDsEnrollResponse> = async ({ token }) => {
	try {
		const request: EcommService.ThreeDsEnrollRequest = {
			paymentLinkToken: token,
		}
		const { data } = await axios.post<EcommService.ThreeDsEnrollResponse>(
			`https://${appConfig.ecommServiceUrl}/v1/enroll`,
			request,
			{ timeout: appConfig.timeout },
		)

		if (EcommService.isThreeDsEnrollResponse(data)) {
			return {
				success: true,
				data: {
					redirectToACSForm: data.redirectToACSForm,
					status: data.status,
				},
			}
		} else {
			return {
				success: false,
				// FIXME: Dont trust our types on the backend
				statusCode: data.errorCode || 500,
				message: data.errorMessage || "",
			}
		}
	} catch (e) {
		return generateHttpError(e)
	}
}

export const isThreeDsPreEnrollResponse = (obj: unknown): obj is ThreeDsPreEnrollResponse => {
	return typeof obj === "object" && obj !== null && "returnContent" in obj && "status" in obj
}

type ThreeDsPreEnrollRequest = {
	pan: string
	/**
	 * YYMM
	 * @example 2201
	 */
	expiryDate: string
	cvv: string
	cardholderName: string
	purchaseAmount: string
	currencyCode: string
	paymentLinkToken: string
}

type ThreeDsPreEnrollResponse = {
	status: string
	returnContent: string
}

export enum PaymentLinkStatus {
	UNPAID = "UNPAID",
	PAID = "PAID",
	EXPIRED = "EXPIRED",
	REFUNDED = "REFUNDED",
	PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
	CANCELED = "CANCELED",
	FAILED = "FAILED",
}

export const threeDsPreEnroll: HttpCall<ThreeDsPreEnrollRequest, ThreeDsPreEnrollResponse> = async (req) => {
	try {
		const request: EcommService.ThreeDsPreEnrollRequest = req

		const { data } = await axios.post<EcommService.ThreeDsPreEnrollResponse>(
			`https://${appConfig.ecommServiceUrl}/v1/pre-enroll`,
			request,
			{ timeout: appConfig.timeout },
		)

		if (EcommService.isThreePreDsPreEnrollResponse(data)) {
			return {
				success: true,
				data: {
					status: data.status,
					//@ts-ignore
					returnContent: data.tdsMethodContent || data.redirectToACSForm,
				},
			}
		} else {
			return {
				success: false,
				// FIXME: Dont trust our types on the backend
				statusCode: data.errorCode || 500,
				message: data.errorMessage || "",
			}
		}
	} catch (e) {
		return generateHttpError(e)
	}
}

/*  preform-transaction call in cres-redirect */
export type PerformTransactionResponse = {
	responseStatus: string
}

function isCNPPaymentErrorResponse(
	data: EcommService.PerformTransactionErrorResponse | EcommService.PerformTransactionResponse,
): data is EcommService.PerformTransactionErrorResponse {
	return (
		(data as EcommService.CNPPaymentErrorResponse).errorResponse !== undefined &&
		(data as EcommService.CNPPaymentErrorResponse).errorResponse.errorCode !== undefined &&
		(data as EcommService.CNPPaymentErrorResponse).errorResponse.errorMessage !== undefined &&
		(data as EcommService.CNPPaymentErrorResponse).errorResponse.displayTryAgain !== undefined
	)
}

export const performTransaction: HttpCall<{ token: string }, PerformTransactionResponse> = async ({ token }) => {
	try {
		const request: EcommService.PerformTransactionRequest = {
			paymentLinkToken: token,
			localTransmissionDateTimeInUnixEpocSec: Math.round(Date.now() / 1000),
		}

		/*json.strigify */
		const { data } = await axios.post<EcommService.PerformTransactionResponse | EcommService.PerformTransactionErrorResponse>(
			`https://${appConfig.ecommServiceUrl}/v1/perform-transaction`,
			request,
			{ timeout: appConfig.timeout },
		)

		const isError = isCNPPaymentErrorResponse(data)

		if (isError) {
			const response: HttpErrorResponse = {
				success: false,
				statusCode: 500,
				cnpErrorDefined: {
					header: data.errorResponse.displayedErrorHeader,
					message: data.errorResponse.displayedErrorMessage,
					tryAgain: data.errorResponse.displayTryAgain,
				},
			}
			return response
		}

		if (EcommService.isPerformTransactionResponse(data)) {
			return {
				success: true,
				data: {
					responseStatus: data.responseStatus,
				},
			}
		} else {
			return {
				success: false,
				// FIXME: Dont trust our types on the backend
				statusCode: data.errorCode || 500,
				message: data.errorMessage || "",
			}
		}
	} catch (e) {
		return generateHttpError(e)
	}
}

export const wixCancelOrder = async (token: string, status = PaymentLinkStatus.CANCELED) => {
	try {
		const config = { headers: { "Content-Type": "application/json" }, timeout: appConfig.timeout }
		const body = { status }
		await axios.post(`https://${appConfig.ecommServiceUrl}/v1/providers/wix/updateRequest/${token}`, body, config)
	} catch (e) {
		return generateHttpError(e)
	}
}

export type PlatformEligibilityConfig = {
	serviceName: string
	title: string
	blurb: string
	iconPath: string
	destinationUrl: string
	ignoreTestPaylink?: boolean
	customUi?: boolean
}

/*
* 	CNP_01 = "CNP01", // Disabled merchant
	CNP_02 = "CNP02", // Restricted mcc
* */
export const getEligiblePaymentMethods: HttpCall<
	{ token: string },
	PlatformEligibilityConfig[] | EcommService.ErrorHeader
> = async ({ token }) => {
	try {
		const config = { headers: { "Content-Type": "application/json" }, timeout: appConfig.timeout }
		const { data } = await axios.get<PlatformEligibilityConfig[] | EcommService.ErrorHeader>(
			`https://${appConfig.ecommServiceUrl}/v1/${token}/platform-eligibility`,
			config,
		)

		if (EcommService.isErrorHeader(data)) {
			return {
				success: false,
				statusCode: data.errorCode || 500,
				message: data.errorMessage || "",
			}
		}

		return {
			success: true,
			data,
		}
	} catch (error) {
		return generateHttpError(error)
	}
}

export const getTransaction: HttpCall<{ token: string; type: string }, CreateTransactionResponse> = async ({ token, type }) => {
	try {
		const config = { headers: { "Content-Type": "application/json" }, timeout: appConfig.timeout }
		const { data } = await axios.get<CreateTransactionResponse>(
			`https://${appConfig.ecommServiceUrl}/v1/transaction/${token}/${type}?width=${window.innerWidth}&height=${window.innerHeight}`,
			config,
		)
		if (!data) return generateHttpError("No transaction data retrieved")
		return {
			success: true,
			data,
		}
	} catch (error) {
		return generateHttpError(error)
	}
}

export const createTransaction: HttpCall<{ token: string; type: string; serviceData: any }, CreateTransactionResponse> = async ({
	token,
	type,
	serviceData,
}) => {
	try {
		const config = { headers: { "Content-Type": "application/json" }, timeout: appConfig.timeout }
		const { data } = await axios.post<CreateTransactionResponse>(
			`https://${appConfig.ecommServiceUrl}/v1/transaction/${token}/${type}?width=${window.innerWidth}&height=${window.innerHeight}`,
			serviceData,
			config
		)
		if (!data) return generateHttpError("No transaction data retrieved")
		return {
			success: true,
			data,
		}
	} catch (error) {
		return generateHttpError(error)
	}
}

export type TransactionData = {
	amount: number
	successUrl: string
	failureUrl: string
	cancelUrl: string
	tryAgainUrl: string
	displayConfig?: {
		backToOrder: boolean
		backToMerchantStore: boolean
	}
	trackEvents?: boolean
	eventsUserIdentities?: UserIdentities
	maskedPan?: string
	cardExpiry?: string
}
export type PerformTransactionCNP = {
	responseCode: string
	responseStatus: string
	errorResponse?: CNPErrorResponse
}

export const isPerformTransactionCNPResponse = (obj: unknown): obj is PerformTransactionCNP => {
	return typeof obj === "object" && obj !== null && "responseStatus" in obj
}
